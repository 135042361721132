import { BookTopicTypes } from './book';
import { CurriculumSearchResultsType } from './curriculum';

export type SearchFacet = Pick<BookTopicTypes, 'key' | 'label' | 'facet' | 'value'>;

export enum SearchFacetParamName {
    AllvitSubjects = 'allvit_subjects',
    LanguagesNo = 'languages_no',
    Publishers = 'publishers',
    PublishedYear = 'published_year',
    FileFormat = 'file_format',
    IncludedInSubscription = 'included_in_subscription',
    FreeBook = 'free_book',
    Rent = 'not rental_price',
    Buy = 'not gross_sales_price',
    Authors = 'authors',
}

export enum SearchParamsNorwegian {
    Subjects = 'fagomrade',
    Languages = 'sprak',
    Publishers = 'forlag',
    PublishedYears = 'utgivelsesar',
    Formats = 'format',
    Subscription = 'allvitPluss',
    FreeBook = 'gratis',
    Rental = 'korttidskjop',
    Buy = 'kjop',
}

export interface SearchFilter {
    facet: SearchFacetParamName | string;
    value: string;
}

export type SearchFilterMap = Array<SearchFilter>;
export interface SearchParams {
    term: string;
    filters: SearchFilterMap;
    size: number;
    total: number;
    start: number;
}

export type BookSearchResults = {
    error: boolean;
    message: string;
    books: { total: number; items: Array<any> };
};

export type StudiesSearchResults = {
    error: boolean;
    message: string;
    curriculumData: CurriculumSearchResultsType;
};

export type ContentSearchResults = {
    error: boolean;
    message: string;
    content: { total: number; items: Array<any> };
};

export interface IncomingSearchTypes {
    id: string;
    content: string;
    searchWord: string;
    chapterUri: string;
}
